import { sprintf } from 'sprintf-js'

export function stringParaSegundos(string, separador = ':') {
  const partesHorario = string.split(separador, 3)

  return (
    parseInt(partesHorario[0], 10) * 3600 +
    parseInt(partesHorario[1], 10) * 60 +
    parseInt(partesHorario[2])
  )
}

export function stringHorasMinutosParaSegundos(string, separador = ':') {
  const partesHorario = string.split(separador, 2)

  return (
    parseInt(partesHorario[0], 10) * 3600 + parseInt(partesHorario[1], 10) * 60
  )
}

export function segundosParaString(segundos, separador = ':') {
  const horas = Math.trunc(segundos / 3600)
  const minutos = Math.trunc((segundos - horas * 3600) / 60)

  return sprintf(
    `%02i${separador}%02i${separador}%02i`,
    horas,
    minutos,
    segundos - horas * 3600 - minutos * 60
  )
}

export function horarioParaSegundos(horario) {
  return horario.hora * 3600 + horario.minuto * 60 + horario.segundo
}

export function compararData(data1, data2, separador = '/') {
  const partesData1 = data1.split(separador, 3)
  const ano1 = parseInt(partesData1[2])
  const mes1 = parseInt(partesData1[1])
  const dia1 = parseInt(partesData1[0])

  const partesData2 = data2.split(separador, 3)
  const ano2 = parseInt(partesData2[2])
  const mes2 = parseInt(partesData2[1])
  const dia2 = parseInt(partesData2[0])

  const comparacaoAno = Math.sign(ano1 - ano2)
  const comparacaoMes = Math.sign(mes1 - mes2) * 0.5
  const comparacaoDia = Math.sign(dia1 - dia2) * 0.25

  return Math.sign(comparacaoAno + comparacaoMes + comparacaoDia)
}

export function stringParaDataHorario(
  string,
  separadorData = '-',
  separadorDataHorario = ' ',
  separadorHorario = ':'
) {
  const dataHorario = string.split(separadorDataHorario, 2)
  const data = dataHorario[0].split(separadorData, 3)
  const horario = dataHorario[1].split(separadorHorario, 3)

  return {
    ano: Number.parseInt(data[0]),
    mes: Number.parseInt(data[1]),
    dia: Number.parseInt(data[2]),
    hora: Number.parseInt(horario[0]),
    minuto: Number.parseInt(horario[1]),
    segundo: Number.parseInt(horario[2]),
  }
}

export function dataHorarioParaString(
  dataHorario,
  separadorData = '-',
  separadorDataHorario = ' ',
  separadorHorario = ':'
) {
  return sprintf(
    `${dataHorario.ano}${separadorData}%02i${separadorData}%02i${separadorDataHorario}%02i${separadorHorario}%02i${separadorHorario}%02i`,
    dataHorario.mes,
    dataHorario.dia,
    dataHorario.hora,
    dataHorario.minuto,
    dataHorario.segundo
  )
}
