<template>
  <v-app>
    <Barra/>
    <v-content class="mx-4 mb-4">
      <router-view></router-view>
    </v-content>
  </v-app>
</template>

<script>
import Barra from './components/Barra';

export default {
  name: 'App',

  components: {
    Barra,
  },

  data: () => ({
    //
  }),
};
</script>
