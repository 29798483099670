import axios from 'axios' 


const BASE_URL = 'https://apimonit.superclient.com.br'
// const BASE_URL = 'http://localhost:8080'

const http = axios.create({
    baseURL: BASE_URL
})


const monit = {

  

  listar: () => {
      return http.get('/services')
      // if (ativos == "Ativos") {
      //   return http.get('/services')
      // } else {
      //   return http.get('/inativos')
      // }
    
  },

  inserirMonit: (novoMonit) => http.post(`/services`, novoMonit),

  ligaDesliga: (id, estado) =>  http.put(`/services/${id}`, { active: estado }),

  salvarMonit: (id, monit) => http.put(`/services/${id}`, monit)
}

export default monit