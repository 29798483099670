import api from '@/services/api.js'

export default {
    create(){

    },
    data() {
       return{
           exibeFormularioMonit: false,
           validarFormulario: false,
            monit: {
                email: [],
                name: '',
                type: 'PING',
                active: false
            },
            nameValidation: [
                nome => !!nome || 'Inserir nome',
            ],
            emailvalidation: [
                mail => !!mail || 'Inserir um e-mail',
                mail => /.+@.+/.test(mail) || 'E-mail com formatação errada' ,
            ]
       }
   },
   methods: {
       async abrirFormulario(){
           this.exibeFormularioMonit = true
           this.monit.email = []
           this.monit.name = ''
           this.monit.active = false
       },
       remove(item){
           this.monit.email.splice(this.monit.email.indexOf(item), 1)
           this.monit.email = [...this.monit.email]
       },
       async inserirMonit(){
           api.inserirMonit(this.monit)
           this.exibeFormularioMonit = false
       },
       async cancelar(){
           this.exibeFormularioMonit = false
           this.monit.email = []
           this.monit.name = ''
           this.monit.active = false
       }
   }
   
}