import { segundosParaString, stringParaSegundos } from '@/utils/conversor-horario.js'
import api from '@/services/api'
import { mdiSleep } from '@mdi/js';

export default {
    created () {
        this.listar()
    },
    data () {
        return {
            center: 'center',
            alertCopia: false,
            msgAlert: '',
            search: '',
            condicao: '',
            filtroAtivos: 0,
            select: true,
            exibId: false,
            monitHibernacao: null,
            monitParaSalvarHibernacao: null,
            exibirHorarioInicioHibernacao: false,
            exibirHorarioFimHibernacao: false,
            id: null,
            headers: [
                {text: 'Ativo', value: 'active', align: ' d-none', filter: value => this.filtroAtivos === 0 ? value : this.filtroAtivos === 1 ? !value : true},
                {text: 'Status', value: 'status'},
                {text: 'Nome', value: 'name'},
                {text: 'Tipo', value: 'type'},
                {text: 'Ultima Atualização', value: 'updated'},
                {text: 'Erros', value: 'qtd_errors'},
                {text: 'Ultimo Ping', value: 'ultimo_ping'},
                {text: 'ID', value: 'identificador'},
                {text: 'Hibernação', value: 'sleep'},
                {text: 'Habilitar', value: 'habilitar'},
            ],
            monits: [],
            icons: {
                mdiSleep
            }
        }
    },
    computed: {
        opcoesFiltroAtivos() {
            return [{text: 'Ativos', value: 0}, {text: 'Inativos', value: 1}, {text: 'Todos', value: 2}]
        },
        opcoesDiasSemana() {
            return [
                { text: 'Domingo', value: 0 },
                { text: 'Segunda', value: 1 },
                { text: 'Terça',   value: 2 },
                { text: 'Quarta',  value: 3 },
                { text: 'Quinta',  value: 4 },
                { text: 'Sexta',   value: 5 },
                { text: 'Sábado',  value: 6 },
            ]
        },
        opcoesDiasSemanaInicio() {
            return this.opcoesDiasSemana.slice(0, this.monitHibernacao.sleep_end_weekday + 1)
        },
        opcoesDiasSemanaFim() {
            return this.opcoesDiasSemana.slice(this.monitHibernacao.sleep_start_weekday)
        },
    },
    methods: {
        async listar() {
            this.monits = (await api.listar()).data || []
            // if (this.select == true){
            //     monits = await api.listar('Ativos')
            //     this.condicao = 'Ativos'
            //     this.dadosMonit.monit = monits.data
            //     monits.data.forEach(monit => {
            //         monit.status == 1 ? monit.icon = 'mdi-thumb-up': monit.icon = 'mdi-thumb-down'
            //         monit.status == 1 ? monit.cor = "green" : monit.cor = "red"
            //         monit.switch = monit.active
            //     })
            // } else {
            //     monits = await api.listar('inativos')
            //     this.condicao = 'Inativos'
            //     this.dadosMonit.monit = monits.data
            //     monits.data.forEach(monit => {
            //         monit.icon = 'mdi-cancel'
            //         monit.cor = 'red'
            //         monit.switch = monit.active
            //     })
            // }
            this.monits.forEach(monit => {
                monit.sleep = monit.sleep || false
                monit.sleep_start_weekday = monit.sleep_start_weekday || 0
                monit.horarioInicioEmSegundos = monit.sleep_start_time
                monit.sleep_start_time = segundosParaString(monit.sleep_start_time) || '00:00:00'
                monit.sleep_end_weekday = monit.sleep_end_weekday || 0
                monit.horarioFimEmSegundos = monit.sleep_end_time
                monit.sleep_end_time = segundosParaString(monit.sleep_end_time) || '00:00:00'
                monit.ultimo_ping ? monit.ultimo_ping = (new Date(monit.ultimo_ping)).toLocaleString() : monit.ultimo_ping = 'Sem dados gerados'
                monit.updated ? monit.updated = (new Date(monit.updated)).toLocaleString() : monit.updated = 'Sem dados gerados'
            });
        },
        async ligarDesligar(monit, valor){    
            monit.active = valor
            api.ligaDesliga(monit._id, monit.active)
        },
        verId(id) {
            this.exibId = true
            this.id = id
        },
        copyId() {
            let copia = document.querySelector('#id_copy')
            copia.setAttribute('type', 'text')

            copia.select()

            try {
                let sucesso = document.execCommand('copy')
                let msg = sucesso ? 'sucedida' : 'não sucedida'
                this.alertCopia = true
                this.mostrarAlerta(`Copia ${msg}`)
            } catch (err) {
                this.alertCopia = true
                this.mostrarAlerta('Ocorreu um erro')
            }
            copia.setAttribute('type', 'hidden')
            window.getSelection().removeAllRanges()
            this.exibId = false
        },
        editarHibernacao(monit) {
            this.monitParaSalvarHibernacao = monit
            this.monitHibernacao = {
                _id: monit._id,
                sleep: monit.sleep,
                sleep_start_weekday: monit.sleep_start_weekday,
                sleep_start_time: monit.sleep_start_time,
                sleep_end_weekday: monit.sleep_end_weekday,
                sleep_end_time: monit.sleep_end_time
            }
        },
        async salvarHibernacao() {
            this.monitHibernacao.horarioInicioEmSegundos = stringParaSegundos(this.monitHibernacao.sleep_start_time)
            this.monitHibernacao.horarioFimEmSegundos = stringParaSegundos(this.monitHibernacao.sleep_end_time)

            await api.salvarMonit(this.monitHibernacao._id, { 
                sleep: this.monitHibernacao.sleep,
                sleep_start_weekday: this.monitHibernacao.sleep_start_weekday,
                sleep_start_time: this.monitHibernacao.horarioInicioEmSegundos,
                sleep_end_weekday: this.monitHibernacao.sleep_end_weekday,
                sleep_end_time: this.monitHibernacao.horarioFimEmSegundos,
            })
            
            Object.assign(this.monitParaSalvarHibernacao, this.monitHibernacao)

            this.fecharHibernacao()
        },
        fecharHibernacao() {
            this.monitHibernacao = null
        },
        mostrarAlerta(msg) {
            this.msgAlert = msg
        },
        fecharAlerta() {
            this.alertCopia = false
        },
        pegarIconeCorMonit(monit) {
            if (!monit.active) { return { icone: 'mdi-cancel', cor: 'red' } }
            
            if (monit.sleep) {
                const data = new Date()

                const diaSemanaParaSegundos = 86400
                const horaParaSegundos = 3600
                const minutoParaSegundos = 60

                const segundos = data.getDay() * diaSemanaParaSegundos 
                + data.getHours() * horaParaSegundos
                + data.getMinutes() * minutoParaSegundos
                + data.getSeconds()
                const inicioHibernacao = monit.sleep_start_weekday * diaSemanaParaSegundos + monit.horarioInicioEmSegundos
                const fimHibernacao = monit.sleep_end_weekday * diaSemanaParaSegundos + monit.horarioFimEmSegundos

                if (inicioHibernacao <= fimHibernacao 
                    ? segundos >= inicioHibernacao && segundos <= fimHibernacao
                    : segundos <= fimHibernacao || segundos >= inicioHibernacao) { 
                    return { icone: 'mdi-sleep', cor: '' } 
                }
            }

            return monit.status === 1 ? { icone: 'mdi-thumb-up', cor: 'green' } : { icone: 'mdi-thumb-down', cor: 'red' }
        }
    }
}